import Link from "next/link";
import Image from "next/image";
import { clsx } from "clsx";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Carousel } from "@/components/layout/Carousel";
import { BodyText, H4 } from "@/components/typography";
import type { StageStoryblok } from "@/component-types-sb";
import { useScrollDirection } from "@/lib/hooks/useScrollDirection";

type StageProps = {
  blok: StageStoryblok;
};

const Stage = ({ blok }: StageProps) => {
  const scrollDirection = useScrollDirection();

  const blurDataUrl =
    blok.blur_data_url ||
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOcGVZUDwAE0wHi5H578QAAAABJRU5ErkJggg==";

  return (
    <section
      className={clsx(
        "lg:max-h-[780px] lg:h-screen min-h-[390px] relative flex items-center justify-start p-4 md:p-24 overflow-hidden transition-top duration-300",
        {
          "w-full mx-auto": blok.layout === "constrained", // .container
          "full-width": blok.layout === "full-width",
          "lg:-mb-96 ease-in": blok.scroll && scrollDirection === "down",
          "lg:-mb-24 ease-out": blok.scroll && scrollDirection !== "down",
        }
      )}
      {...storyblokEditable(blok)}
    >
      <div className="relative z-10 text-left">
        {blok.overline && blok.overline.length > 0 && (
          <div className="my-4">
            <H4 colorScheme="primary" fontSize="lg" fontWeight="bold">
              {blok.overline}
            </H4>
          </div>
        )}

        {blok.headline && blok.headline.length > 0 && (
          <h1 className="my-4 uppercase first-line:normal-case text-white font-bold text-5xl md:text-6xl first-line:text-3xl md:first-line:text-4xl inline-block max-w-min">
            {blok.headline}
          </h1>
        )}

        <BodyText className="text-white max-w-xs my-4">{blok.body}</BodyText>

        {blok.button?.map((nestedBlok) => (
          <Link
            href={nestedBlok.link?.cached_url}
            key={nestedBlok._uid}
            className="my-4"
          >
            <StoryblokComponent blok={nestedBlok} />
          </Link>
        ))}
      </div>

      {blok.images?.length && blok.images?.length > 1 ? (
        <div className="absolute top-0 left-0 z-0 w-full h-full">
          <Carousel
            id="stage-carousel"
            className="basis-full"
            autoplay
            options={{ loop: true }}
          >
            {blok.images?.map((image: any, index: number) => (
              <Image
                key={image.id}
                className="w-full h-[500px] lg:h-[780px] object-cover"
                src={image.filename}
                width={1780}
                height={780}
                alt={image.alt || ""}
                title={image.title || ""}
                placeholder={index === 0 ? "blur" : "empty"}
                blurDataURL={blurDataUrl}
                priority={index === 0 && blok.priority}
              />
            ))}
          </Carousel>
        </div>
      ) : blok.images?.[0] ? (
        <Image
          className="absolute top-0 left-0 z-0 w-full h-full object-cover"
          src={blok.images?.[0].filename}
          width={1780}
          height={780}
          alt={blok.images?.[0].alt || ""}
          title={blok.images?.[0].title || ""}
          placeholder={"blur"}
          blurDataURL={blurDataUrl}
          priority={blok.priority}
        />
      ) : null}
    </section>
  );
};

export default Stage;
